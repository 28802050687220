<script>
import CMSEditCard from "@/views/components/cms/CMSEditCard.vue";

export default {
  name: "RentenClearUpEdit",
  components: {CMSEditCard},
}
</script>

<template>
  <CMSEditCard></CMSEditCard>
</template>

<style scoped>

</style>
