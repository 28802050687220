<script>

import NewArticle from "@/views/components/cms/new/NewArticle.vue";

export default {
  name: "ClearUpNew",
  components: {
    NewArticle,
  },
}
</script>

<template>
  <NewArticle :category-id="1"></NewArticle>
</template>

<style scoped>

</style>
