import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
  async getUsers(params) {
    console.log("params", params)
    const response = await axios.get(API_URL + "users", {
      headers: authHeader(),
      params: {
        query: params.query,
        currentPerPage: params.currentPerPage,
        currentPage: params.currentPage,
        sort: params.sort,
      }
    });
    return {
      data: response.data,
    };
  },

  async getUser(id) {
    const response = await axios.get(API_URL + "user/" + id, {
      headers: authHeader(),
    });
    return {
      data: response.data,
    };
  },

  async deleteUser(id) {
    const response = await axios.delete(API_URL + "users/" + id.toString(), {
      headers: authHeader(),
    });
    return {
      data: response.data,
    };
  },

  async addUser(user) {
    return await axios.post(API_URL + "user/create", user, {
      headers: authHeader(),
    });
  },

  async resetPassword(id) {
    const response = await axios.post(API_URL + "users/password-reset/" + id, {}, {
      headers: authHeader(),
    });
    return {
      data: response.data,
    };
  },

  async editUser(user) {
    const response = await axios.put(API_URL + "users", user, {
        headers: authHeader(),
    });
    return {
      data: response.data,
    };
  },
};
