<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">{{ meeting.meetingName }}</h5>
              </div>
              <div v-if="requireAdmin" class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link
                      class="mb-0 btn bg-gradient-success btn-sm"
                      :to="{name: 'Edit Meeting', params: {id: meeting.id}}"
                  >Meeting Bearbeiten
                  </router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="text-center col-xl-5 col-lg-6">
                <!--img
                  class="mx-auto shadow-lg w-100 border-radius-lg"
                  src="https://images.unsplash.com/photo-1616627781431-23b776aad6b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1884&q=80"
                  alt="chair"
                /-->
                <video
                    id="video"
                    class="mx-auto shadow-lg w-100 border-radius-lg"
                    :src="streamUrl"
                    controls
                    preload="auto"
                    @timeupdate="updateProgress"
                />
                <div v-if="files.length !== 0">
                  <div class="card-header pb-0 p-3">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <h6 class="mb-0">Dateien</h6>
                      </div>
                      <!--div class="col-6 text-end">
                        <soft-button color="success" size="sm" variant="outline"
                        >View All
                        </soft-button
                        >
                      </div-->
                    </div>
                  </div>
                  <div class="card-body p-3 pb-0 mb-0">
                    <ul class="list-group">
                      <li v-for="file in files" :key="file.id"
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                      >
                        <div class="d-flex flex-column">
                          <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            {{ shortenName(file.fileName) }}
                          </h6>
                        </div>
                        <div class="d-flex align-items-center text-sm">
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                                  @click="directToToFile(file)">
                            <i :class="file.fileTypeObject.icon + ' text-lg me-1'"
                                aria-hidden="true"></i>
                            {{file.fileTypeObject.name}}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mx-auto col-lg-5">
                <!--h3 class="mt-4 mt-lg-0">{{ meeting.meetingName }}</h3-->
                <!--div class="rating">
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                </div-->
                <h6 class="mt-3 mb-0">Veröffentlich von: {{meeting.uploadedByFullname}}</h6>
                <h5>Referent: {{ meeting.referent }}</h5>
                <span v-for="tag in meeting.tags" :key="tag" class="badge badge-success"
                      style="margin-right: 5px">{{ tag }}</span>
                <br/>
                <label class="mt-4">Bescheibung</label>
                <p v-html="meeting.description"></p>
                <div class="mt-4 row">
                  <div class="col-lg-5">
                    <button
                        v-if="fullySeen"
                        class="mb-0 btn bg-gradient-success mt-lg-auto w-100"
                        type="button"
                        name="button"
                    >
                      SCHON GESEHEN
                    </button>

                    <button
                        v-if="!fullySeen"
                        class="mb-0 btn bg-gradient-danger mt-lg-auto w-100"
                        type="button"
                        name="button"
                    >
                      NOCHT NICHT GESEHEN
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 row">
              <other-meeting-videos v-if="string_tags.length !== 0" :current-meeting-id="meeting.id"
                                    :tags="meeting.tags"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Choices from "choices.js";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import OtherMeetingVideos from "@/views/meetings/components/OtherMeetingVideos.vue";

export default {
  name: "MeetingVideoPage",
  components: {OtherMeetingVideos},
  data() {
    return {
      meeting: Object,
      streamUrl: "", // Initialize streamUrl to an empty string
      string_tags: [],
      files: [],
      progress: 0.00,
      videoFullyLoaded: false,
      progressSet: false,
      fullySeen: false,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.scope == 2) return true;
      }
      return false;
    },
  },
// watch if the params.id changes
  watch: {
    async $route(to, from) {
      if (to.params?.id !== from.params?.id) {
        //
        try {
          const meeting = await this.$store.dispatch("meetings/getMeeting", this.$route.params.id);

          this.meeting = meeting.data;
          this.streamUrl = this.meeting.streamUrl;
          this.string_tags = this.meeting.tags.map((tag) => tag);

          const meetingFiles = await this.$store.dispatch("meetings/getMeetingFiles", this.$route.params.id);

          this.files = meetingFiles.data;

          this.files.forEach((file) => {
            file.fileTypeObject = this.detectFileType(file.fileType);
          });

        } catch (error) {
          // back to list page
        }

        try {
          const meetingProgress = await this.$store.dispatch("meetings/getMeetingProgress", this.$route.params.id);
          this.progress = meetingProgress.data.progress;
          this.fullySeen = meetingProgress.data.completed;
        } catch (error) {
          console.log(error);
        }

      }
    },
  },
  async created() {
    try {
      const meeting = await this.$store.dispatch("meetings/getMeeting", this.$route.params.id);

      this.meeting = meeting.data;
      this.streamUrl = this.meeting.streamUrl;
      this.string_tags = this.meeting.tags.map((tag) => tag);

      const meetingFiles = await this.$store.dispatch("meetings/getMeetingFiles", this.$route.params.id);

      this.files = meetingFiles.data;

      this.files.forEach((file) => {
        file.fileTypeObject = this.detectFileType(file.fileType);
      });

    } catch (error) {
      // back to list page
      this.$router.back()
    }

    try {
      const meetingProgress = await this.$store.dispatch("meetings/getMeetingProgress", this.$route.params.id);
      this.progress = meetingProgress.data.progress;
      this.fullySeen = meetingProgress.data.completed;
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    const bootstrap = this.$store.state.bootstrap;

    this.getChoices("choices-quantity");
    this.getChoices("choices-material");
    this.getChoices("choices-colors");

    // Gallery Carousel
    if (document.getElementById("products-carousel")) {
      var myCarousel = document.querySelector("#products-carousel");
      // eslint-disable-next-line no-unused-vars
      var carousel = new bootstrap.Carousel(myCarousel);
    }

    // Products gallery

    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element
          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          size = linkEl.getAttribute("data-size").split("x");

          // create slide object
          item = {
            src: linkEl.getAttribute("href"),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10)
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute("src");
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
          return el.tagName && el.tagName.toUpperCase() === "FIGURE";
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
            params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split("&");
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split("=");
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
          index,
          galleryElement,
          disableAnimation,
          fromURL
      ) {
        var pswpElement = document.querySelectorAll(".pswp")[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute("data-pswp-uid"),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
                pageYScroll =
                    window.pageYOffset || document.documentElement.scrollTop,
                rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width
            };
          }
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            items,
            options
        );
        gallery.init();
      };

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute("data-pswp-uid", i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
            hashData.pid,
            galleryElements[hashData.gid - 1],
            true,
            true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM(".my-gallery");

    const videoElement = document.getElementById("video");
    videoElement.addEventListener("canplay", this.onVideoReady);
  },
  methods: {
    detectFileType(fileType) {
      switch (fileType) {
        case "application/pdf":
          return {
            icon: "fas fa-file-pdf",
            name: "PDF"
          };
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return {
            icon: "fas fa-file-word",
            name: "Word"
          };
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return {
            icon: "fas fa-file-excel",
            name: "Excel"
          };
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return {
            icon: "fas fa-file-powerpoint",
            name: "Powerpoint"
          };
        case "image/png":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/jpeg":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/gif":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "video/mp4":
          return {
            icon: "fas fa-file-video",
            name: "Video"
          };
        default:
          return {
            icon: "fas fa-file",
            name: "Datei"
          };
      }
    },
    directToToFile(file) {
      const externalUrl = file.fileUrl;
      if (externalUrl) {
        window.open(externalUrl, "_blank");
      }
    },
    shortenName(name) {
      if (name.length > 30) {
        return name.substring(0, 30) + "...";
      }
      return name;
    },
    directToMeeting(id) {
      this.$router.push({name: "Meeting", params: {id: id}});
    },
    onVideoReady() {
      // When the video is ready, set the flag to true
      this.videoFullyLoaded = true;
      if (!this.progressSet) {
        this.setProgress();
      }
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
          itemSelectText: ""
        });
      }
    },
    setProgress() {
      const video = document.getElementById("video");
      const duration = video.duration;
      // if the progress is 100, the video will start from the beginning
      if (this.progress === 100) {
        this.progress = 0;
      }
      video.currentTime = (this.progress / 100) * duration;
      this.progressSet = true;
    },
    updateProgress(event) {
      // Calculate the progress as a percentage
      // Check if the video is ready before attempting to update progress
      if (!this.videoFullyLoaded) {
        return;
      }
      const video = event.target;
      const currentTime = video.currentTime;
      const duration = video.duration;
      const progress = (currentTime / duration) * 100;

      // Update the progress in your component's data
      this.progress = progress;

      // You can also dispatch an action to update the progress on the server if needed
      const meetingProgress = {
        meetingVideoId: this.meeting.id,
        progress: progress
      };
      this.$store.dispatch("meetings/updateMeetingProgress", meetingProgress);
    }
  }
};
</script>
