<script>
import Quill from "quill";
import Choices from "choices.js";
import showSwal from "@/mixins/showSwal";
import store from "/src/store";
import _ from "lodash";
import UploadElement from "@/views/components/io/UploadElement.vue";

const getUsersList = _.debounce(async function () {
  await store.dispatch("users/getUsers", {
    query: "",
    sort: "id",
    currentPerPage: "",
    currentPage: "",
  });
}, 300);

export default {
  name: "CMSEditCard",
  components: {UploadElement},
  data() {
    return {
      article: Object,
      streamUrl: "", // Initialize streamUrl to an empty string
      users: [],
      files: [],
      usersLoaded: false,
      quill: Object,
      thumbnailImage: "",
    };
  },
  computed: {
    // Get the current user from the store
    usersList() {
      return this.$store.getters["users/users"]?.data;
    },
  },
  watch: {
    // Watch for changes to the usersList computed property
    usersLoaded() {
      if (this.usersLoaded) {
        // If the usersList is loaded, set the selected user
        this.getChoices("choices-referent-edit");
      }
    },
  },
  async created() {
    try {
      const article = await store.dispatch("cms/getArticle", {
        id: this.$route.params.id,
        withStream: true,
      });

      this.article = article.data;

      await getUsersList({
        query: "",
        sort: "id",
        currentPerPage: "100",
        currentPage: "1",
      });
      this.users = this.usersList;
      this.usersLoaded = true

      this.thumbnailImage = this.article.thumbnailUrl;
      this.streamUrl = this.article.streamUrl;

      this.quill.root.innerHTML = this.article.description;

      // if files are null, set files to empty array
      if (this.article.files === null) {
        this.article.files = [];
      }

      this.files = this.article.files;

      // only loop through files if there are files
      this.files.forEach((file) => {
        file.fileTypeObject = this.detectFileType(file.fileType);
      });

    } catch (error) {
      // back to list page
      console.log(error)
      //this.$router.back()
    }
  },
  mounted() {

    if (document.getElementById("edit-description-edit")) {
      this.quill = new Quill("#edit-description-edit", {
        theme: "snow", // Specify theme in configuration
      });
    }
    // Watch for changes in the Quill editor's content
    this.quill.on("text-change", () => {
      this.article.description = this.quill.root.innerHTML;
    });
  },
  methods: {
    handleReferentChange(event) {
      this.article.createdBy = parseInt(event.target.value)
    },
    deleteFile(file) {
      try {
        this.$store.dispatch("cms/deleteFileById", file.id)
      } catch (e) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Die Datei " + file.fileName + " konnte nicht gelöscht werden.",
        });
      } finally {
        showSwal.methods.showSwal({
          type: "success",
          message: "Die Datei wurde erfolgreich gelöscht.",
        });
        // remove file from list
        this.files = this.files.filter((f) => f.id !== file.id);
      }
    },
    shortenName(name) {
      if (name.length > 30) {
        return name.substring(0, 30) + "...";
      }
      return name;
    },
    detectFileType(fileType) {
      switch (fileType) {
        case "application/pdf":
          return {
            icon: "fas fa-file-pdf",
            name: "PDF"
          };
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return {
            icon: "fas fa-file-word",
            name: "Word"
          };
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return {
            icon: "fas fa-file-excel",
            name: "Excel"
          };
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return {
            icon: "fas fa-file-powerpoint",
            name: "Powerpoint"
          };
        case "image/png":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/jpeg":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/gif":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "video/mp4":
          return {
            icon: "fas fa-file-video",
            name: "Video"
          };
        default:
          return {
            icon: "fas fa-file",
            name: "Datei"
          };
      }
    },
    directToToFile(file) {
      const externalUrl = file.fileUrl;
      if (externalUrl) {
        window.open(externalUrl, "_blank");
      }
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        const element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
    saveMeeting() {
      try {
       this.$store.dispatch("cms/updateArticle", {
          article: this.article,
          hasVideo: false,
          files: null,
        })
      } catch (e) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Der Artikel konnte nicht gespeichert werden.",
        });
        console.log(e)
      } finally {
        showSwal.methods.showSwal({
          type: "success",
          message: "Der Artikel wurde erfolgreich gespeichert.",
        });
        // wait 2 seconds
        setTimeout(() => {
          this.$router.push({name: "RentenClearUp", params: {id: this.article.id}});
        }, 2000);
      }
    }
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4>Bitte hier Änderungen des Artikels ({{ article.articleTitle }}) vornehmen</h4>
        <p>
          Bitte nimm Änderungen nur vor, wenn du dir sicher bist, dass diese Änderungen sinnvoll sind.
        </p>
      </div>
      <div
          class="text-right col-lg-6 d-flex flex-column justify-content-center"
      >
        <button
            type="button"
            class="mt-2 mb-0 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto mt-lg-0"
            @click="saveMeeting()"
        >
          Speichern
        </button>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <div v-if="article.hasVideo">
              <h5 class="font-weight-bolder">Artikel Video</h5>
              <label>Aus Sicherheitsgründen ist die Änderung des Videos nicht möglich.</label>
              <div class="row">
                <div class="col-12">
                  <img
                      class="mt-3 shadow-lg w-100 border-radius-lg"
                      :src="thumbnailImage"
                      alt="product_image"
                  />
                </div>
              </div>
              <br>
            </div>
            <UploadElement :parent-name="article.articleTitle" :parent-id="article.id" :endpoint="'cms/files/' + article.id"></UploadElement>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Artikel Information</h5>
            <div class="row">
              <div class="col-12 col-sm-12">
                <label>Name</label>
                <input
                    v-model="article.articleTitle"
                    class="form-control"
                    type="text"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <label class="mt-4">Veröffentlich von</label>
              <select
                  v-if="usersLoaded"
                  id="choices-referent-edit"
                  class="form-control"
                  name="choices-referent"
                  :value="article.createdBy"
                  @change="handleReferentChange($event)"
              >
                <option v-for="user in usersList" :key="user.id" :value="user.id">{{ user.name }}</option>
              </select>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="mt-4">Beschreibung</label>
                <p class="text-xs form-text text-muted ms-1 d-inline">
                  (optional)
                </p>
                <div id="edit-description-edit" class="h-50">
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="files.length !== 0" class="mt-4 row">
      <div class="mt-4 col-sm-12 mt-sm-0">
        <div class="card">
          <div class="card-body p-3 pb-0 mb-0">
            <h5 class="font-weight-bolder">Dateien</h5>
            <ul class="list-group">
              <li v-for="file in files" :key="file.id"
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              >
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark font-weight-bold text-sm">
                    {{ shortenName(file.fileName) }}
                  </h6>
                </div>
                <div class="d-flex align-items-center text-sm">
                  <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" style="margin-right: 50px;"
                          @click="directToToFile(file)">
                    <i :class="file.fileTypeObject.icon + ' text-lg me-1'"
                       aria-hidden="true"></i>
                    {{ file.fileTypeObject.name }}
                  </button>
                  <button class="mt-2 mb-0 btn bg-gradient-danger ms-lg-auto me-lg-0 me-auto mt-lg-0"
                          @click="deleteFile(file)">
                    LÖSCHEN
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
