<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Erstelle einen neuen Benutzer</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="/examples/users/list" class="mb-0 btn bg-gradient-success btn-sm"
                    >&nbsp; Zurück zur Liste</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <label class="form-label mt-2 row mt-4">Name</label>

            <soft-model-input
              id="name"
              v-model="user.name"
              type="text"
              placeholder="Voller Name des Benutzers"
            />
            <validation-error :errors="apiValidationErrors.name" />

            <label class="form-label mt-2 row mt-4">Email</label>
            <soft-model-input
              id="email"
              v-model="user.email"
              type="text"
              placeholder="Email"
            />
            <validation-error :errors="apiValidationErrors.email" />

            <label class="form-label mt-2 row mt-4">Rolle</label>
            <select
              id="choices-roles"
              v-model="getRole"
              class="form-control"
              name="choices-roles"
            ></select>
            <validation-error :errors="apiValidationErrors.roles" />

            <h5 class="text-secondary font-weight-normal" style="margin-top: 70px;">
              Der neue Benutzer wird eine E-Mail mit einem Passwort erhalten, um sich anzumelden.
            </h5>

            <soft-button
              color="dark"
              variant="gradient"
              class="float-end mt-4 mb-0"
              size="sm"
              :is-disabled="loading ? true : false"
              @click="newUser"
              ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Bestätigen</span></soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import Choices from "choices.js";

export default {
  name: "NewUser",
  components: {
    SoftModelInput,
    SoftButton,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      file: null,
      user: {},
      loading: false,
      getRole: null,
      options: [],
    };
  },
  computed: {
    roles() {
      var response = [
        {
          value: "Admin",
          label: "Admin",
          id: 2,
        },
        {
          value: "User",
          label: "User",
          id: 1,
        },
        {
          value: "Gruppenleiter",
          label: "Gruppenleiter",
          id: 57,
        }
      ]
      switch (this.profile.scope) {
        case 1:
          response = [];
          break;
        case 2:
          break;
        case 57:
          response = response.filter((obj) => {
            return obj.value === "User";
          });
          break;
      }
      return response;
    },
    profile() {
      return this.$store.getters["profile/profile"];
    },
    preview() {
      if (this.file) return URL.createObjectURL(this.file);
      else return require("/src/assets/img/placeholder.jpg");
    },
  },

  async mounted() {

    if (document.getElementById("choices-roles")) {
      var element = document.getElementById("choices-roles");
      new Choices(element, {
        searchEnabled: false,
        choices: [
          {
            value: "",
            label: "",
            selected: true,
            disabled: true,
          },
        ].concat(this.roles),
      });
    }
  },

  methods: {
    getMaxRoles() {

    },
    async newUser() {
      this.resetApiValidation();
      this.loading = true;

      const id = this.roles.filter((obj) => {
        return obj.value === this.getRole;
      })[0]?.id;

      if (id)
        this.user.roles = [
          {
            type: "roles",
            id: id,
          },
        ];

      try {

        this.user.scope = this.returnGroupId(this.getRole);

        console.log('user', this.user)

        await this.$store.dispatch("users/addUser", this.user);

        showSwal.methods.showSwal({
          type: "success",
          message: "Benutzer " + this.user.name + " erfolgreich erstellt",
        });
        await this.$router.push("/management/users/list");
      } catch (error) {
        if (error.response.data.errors) this.setApiValidation(error.response.data.errors);
        else
          showSwal.methods.showSwal({
            type: "error",
            message: "Oops, something went wrong!",
            width: 350,
          });
        this.loading = false;
      }
    },
    addedImage(files) {
      this.file = files[0];
    },
    returnGroupName(scopeId) {
      switch (scopeId) {
        case 1:
          return "User";
        case 2:
          return "Admin";
        case 57:
          return "Gruppenleiter";
        default:
          return "User";

      }
    },
    returnGroupId(groupName) {
      switch (groupName) {
        case "User":
          return 1;
        case "Admin":
          return 2;
        case "Gruppenleiter":
          return 57;
        default:
          return 1;
      }
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices{
  margin-bottom: 0px !important;
}
</style>
