<script >

import ArticleInfo from "@/views/components/cms/new/components/ArticleInfo.vue";
import setNavPills from "@/assets/js/nav-pills";
import CmsMedia from "@/views/components/cms/new/components/CmsMedia.vue";
import CmsDocuments from "@/views/components/cms/new/components/CmsDocuments.vue";

export default {
  name: "NewArticle",
  components: {CmsDocuments, ArticleInfo, CmsMedia},
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      article: {
        articleTitle: "",
        description: "",
        id: 0,
        active: true,
      },
      file: null,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        console.log("going to next step", this.activeStep)
        this.activeStep += 1;
      } else {
        console.log("going to prev step", this.activeStep)
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
  },
}

</script>

<template>
  <div class="container-fluid">
    <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
                src="../../../../assets/img/d320-icon.jpg"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Willkommen, {{profile.name}}</h5>
            <!--p class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p-->
          </div>
        </div>
        <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="mx-auto mt-4 mb-3 col-12 col-lg-8 mb-sm-5">
              <div class="multisteps-form__progress">
                <button
                    class="multisteps-form__progress-btn js-active"
                    type="button"
                    title="Artikel Info"
                    :class="activeStep >= 0 ? activeClass : ''"
                    @click="activeStep = 0"
                >
                  <span>1. Artikel Informationen</span>
                </button>
                <button
                    class="multisteps-form__progress-btn"
                    type="button"
                    title="Socials"
                    :class="activeStep >= 1 ? activeClass : ''"
                    @click="activeStep = 1"
                >
                  2. Video Hochladen
                </button>
                <button
                    class="multisteps-form__progress-btn"
                    type="button"
                    title="Socials"
                    :class="activeStep >= 2 ? activeClass : ''"
                    @click="activeStep = 2"
                >
                  3. Dokumente Hochladen
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <form class="mb-8 multisteps-form__form">
                <!--single form panel-->
                <ArticleInfo :class="activeStep === 0 ? activeClass : ''" />
                <!--single form panel-->
                <CmsMedia :category-id="categoryId" :class="activeStep === 1 ? activeClass : ''" />
                <!--single form panel-->
                <CmsDocuments :article-id="article.id" :class="activeStep === 2 ? activeClass : ''" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

</style>
