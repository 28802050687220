<script>
export default {
  name: "CMSSinglePage",
  props: {
    listElement: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      article: Object,
      streamUrl: "", // Initialize streamUrl to an empty string
      files: [],
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.scope == 2) return true;
      }
      return false;
    },
  },
// watch if the params.id changes
  watch: {
  },
  async created() {
    try {
      const article = await this.$store.dispatch("cms/getArticle", {
        id: this.$route.params.id,
        withStream: true,
      });

      this.article = article.data;
      this.streamUrl = this.article.streamUrl;

      this.files = this.article.files;

      if (this.article.files !== null) {
        this.files.forEach((file) => {
          file.fileTypeObject = this.detectFileType(file.fileType);
        });
      }

    } catch (error) {
      // back to list page
      console.log(error)
      this.$router.back()
    }
  },
  methods: {
    detectFileType(fileType) {
      switch (fileType) {
        case "application/pdf":
          return {
            icon: "fas fa-file-pdf",
            name: "PDF"
          };
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return {
            icon: "fas fa-file-word",
            name: "Word"
          };
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return {
            icon: "fas fa-file-excel",
            name: "Excel"
          };
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return {
            icon: "fas fa-file-powerpoint",
            name: "Powerpoint"
          };
        case "image/png":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/jpeg":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/gif":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "video/mp4":
          return {
            icon: "fas fa-file-video",
            name: "Video"
          };
        default:
          return {
            icon: "fas fa-file",
            name: "Datei"
          };
      }
    },
    directToToFile(file) {
      const externalUrl = file.fileUrl;
      if (externalUrl) {
        window.open(externalUrl, "_blank");
      }
    },
    shortenName(name) {
      if (name.length > 50) {
        return name.substring(0, 50) + "...";
      }
      return name;
    },
  }
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <router-link :to="{name: listElement}"
                           class="mb-0 btn bg-gradient-primary btn-sm" style="margin-right: 10px" >
                Zurück
              </router-link>
              <div>
                <h5 class="mb-0">{{ article.articleTitle }}</h5>
              </div>
              <div v-if="requireAdmin" class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link
                      class="mb-0 btn bg-gradient-success btn-sm"
                      :to="{name: 'RentenClearUpEdit', params: {id: article.id}}"
                  >Artikel Bearbeiten
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="text-center col-xl-5 col-lg-6">
                <!--img
                  class="mx-auto shadow-lg w-100 border-radius-lg"
                  src="https://images.unsplash.com/photo-1616627781431-23b776aad6b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1884&q=80"
                  alt="chair"
                /-->
                <video
                    v-if="article.hasVideo"
                    id="video"
                    class="mx-auto shadow-lg w-100 border-radius-lg"
                    :src="streamUrl"
                    controls
                    preload="auto"
                />
                <div v-if="this.article.files">
                  <div class="card-header pb-0 p-3">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <h6 class="mb-0">Dateien</h6>
                      </div>
                      <!--div class="col-6 text-end">
                        <soft-button color="success" size="sm" variant="outline"
                        >View All
                        </soft-button
                        >
                      </div-->
                    </div>
                  </div>
                  <div class="card-body p-3 pb-0 mb-0">
                    <ul class="list-group">
                      <li v-for="file in files" :key="file.id"
                          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                      >
                        <div class="d-flex flex-column">
                          <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            {{ shortenName(file.fileName) }}
                          </h6>
                        </div>
                        <div class="d-flex align-items-center text-sm">
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                                  @click="directToToFile(file)">
                            <i :class="file.fileTypeObject.icon + ' text-lg me-1'"
                               aria-hidden="true"></i>
                            {{file.fileTypeObject.name}}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mx-auto col-lg-5">
                <!--h3 class="mt-4 mt-lg-0">{{ meeting.meetingName }}</h3-->
                <!--div class="rating">
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                </div-->
                <h6 class="mt-3 mb-0">Veröffentlich von: {{article.createdByName}}</h6>

                <br/>
                <label class="mt-4">Bescheibung</label>
                <p v-html="article.description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
