<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Meeting Informationen</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-12" style="margin-bottom: 15px">
          <label>Name</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            v-model="this.$parent.meeting.meetingName"
            placeholder="Bsp.: Führungskräftemeeting"
          />
        </div>
        <div class="col-12 col-sm-12">
          <label>Referent</label>
          <input
              class="multisteps-form__input form-control"
              type="text"
              v-model="this.$parent.meeting.referent"
              placeholder="Bsp.: Sven Alflen"
          />
        </div>
        <div class="col-12 col-sm-12">
          <label class="mt-4">Hochgeladen von</label>
          <select
              v-if="usersLoaded"
              id="choices-referent-edit"
              class="form-control"
              name="choices-referent"
              @change="handleReferentChange($event)"
          >
            <option v-for="user in usersList" :key="user.id" :value="user.id">{{user.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label class="mt-4">Beschreibung</label>
          <p class="text-xs form-text text-muted ms-1 d-inline">
            (optional)
          </p>
          <div id="edit-description-edit" class="h-50">
            123 wef we
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="this.$parent.nextStep"
          >WEITER</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import _ from "lodash";
import store from "@/store";

const getUsersList = _.debounce(async function () {
  await store.dispatch("users/getUsers", {
    query: "",
    sort: "id",
    currentPerPage: "",
    currentPage: "",
  });
}, 300);

export default {
  name: "MeetingInfo",
  components: {
    SoftButton,
  },
  data() {
    return {
      usersLoaded: false,
    };
  },
  computed: {
    // Get the current user from the store
    usersList() {
      return this.$store.getters["users/users"]?.data;
    },
  },
  mounted() {
    if (document.getElementById("edit-description")) {
      // eslint-disable-next-line no-unused-vars
     new Quill("#edit-description", {
        theme: "snow", // Specify theme in configuration
      });
    }
    if (document.getElementById("choices-category")) {
      const element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-sizes")) {
      let element = document.getElementById("choices-sizes");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("edit-description-edit")) {
      this.quill = new Quill("#edit-description-edit", {
        theme: "snow", // Specify theme in configuration
      });
      this.quill.root.innerHTML = this.$parent.meeting.description;
    }

    // Watch for changes in the Quill editor's content
    this.quill.on("text-change", () => {
      // Update the meeting's description property with the editor's content
      this.$parent.meeting.description = this.quill.root.innerHTML;
    });
  },
  async created() {
    await getUsersList({
      query: "",
      sort: "id",
      currentPerPage: "100",
      currentPage: "1",
    });
    this.usersLoaded = true
  },
  methods: {
    handleReferentChange(event) {
      this.$parent.meeting.uploadedBy = parseInt(event.target.value)
    },
  },
};
</script>
