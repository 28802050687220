<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Video (optional)</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12" v-if="uploading">
          <label>Upload Fortschritt: {{progress}}%</label>
          <div class="mx-auto progress">
            <div
                class="progress-bar bg-gradient-success"
                role="progressbar"
                :style="'width: ' + progress + '%;'"
                :aria-valuenow="progress"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
          </div>
        </div>
        <br>
        <div class="col-12">
          <label>Bitte hier das Video hochladen</label>
          <form
              id="dropzone"
              action="/file-upload"
              class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file"/>
            </div>
          </form>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
        >Zurück
        </soft-button
        >
        <soft-button
            :is-disabled="uploading"
            type="button"
            color="success"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Erstellen"
            @click="startFileUpload"
        >Hochladen
        </soft-button>
        <soft-button
            type="button"
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Erstellen"
            @click="this.continue()"
        >{{getButtonName()}}
        </soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Dropzone from "dropzone";
import authHeader from "@/services/auth-header";
import cmsService from "@/services/cms.service";

export default {
  name: "CmsMedia",
  components: {
    SoftButton,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dropZone: null,
      uploading: false,
      progress: 0,
      sentBytes: 0,
      completed: false,
    };
  },
  mounted() {
    Dropzone.autoDiscover = false;
    const self = this;
    const drop = document.getElementById("dropzone");
    this.dropZone = new Dropzone(drop, {
      addRemoveLinks: true,
      uploadMultiple: false,
      acceptedFiles: "video/*",
      autoProcessQueue: false,
      maxFilesize: 3000,
      headers: {
        "Authorization": authHeader().Authorization,
      },
    });
    this.dropZone.on('sending', function (file, xhr, formData) {
      self.$parent.article.cmsCategory = self.categoryId;
      const meetingJson = JSON.stringify(self.$parent.article);
      formData.append('article', meetingJson);
      formData.append('hasVideo', true);
    });

    this.dropZone.on("uploadprogress", function (file, progress, bytesSent) {
      self.progress = Math.round((bytesSent / file.size) * 100);
    });
    this.dropZone.on("success", function (file, response) {
      self.$parent.article.id = response.data.id;
      self.$swal({
        icon: "success",
        title: "Der Artikel '"+self.$parent.article.articleTitle+"' wurde erfolgreich hochgeladen'!",
        text: "Du kannst nun eventuelle Dokumente hochladen.",
        type: "success-message",
      });
      self.progress = 100;
      self.completed = true;
    });
  },
  methods: {
    startFileUpload() {
      // Trigger the file upload process here
      console.log("Start file upload");
      if (this.dropZone) {
        // env variable for api endpoint
        const baseEndpoint = process.env.VUE_APP_API_BASE_URL + '/';
        this.dropZone.options.url = baseEndpoint + "cms/articles";
        this.dropZone.options.method = "POST";
        console.log("endpoint", this.dropZone.options.url)
        this.dropZone.processQueue();
        this.uploading = true;
      }
    },
    async continue() {
      if (this.completed) {
        this.$parent.nextStep();
      } else {
        this.$parent.article.cmsCategory = this.categoryId;
        const response = await cmsService.createArticle(this.$parent.article, false, null);
        this.$parent.article.id = response.data.id;
        this.$swal({
          icon: "success",
          title: "Der Artikel '"+this.$parent.article.articleTitle+"' wurde erfolgreich hochgeladen'!",
          text: "Du kannst nun eventuelle Dokumente hochladen.",
          type: "success-message",
        });
        this.$parent.nextStep();
      }
    },
    getButtonName() {
      if (this.completed) {
        return "WEITER";
      }
      return "WEITER OHNE VIDEO";
    },
  },
};
</script>
