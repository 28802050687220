import vorsorgeaufstellungService from "@/services/vorsorgeaufstellung.service";

const initialState = { vorsorgeAufstellung: null };

export const vorsorgeaufstellung = {
  namespaced: true,
  state: initialState,
  actions: {
    async createVorsorgeaufstellung(_, object) {
      return await vorsorgeaufstellungService.createVorsorgeaufstellung(object);
    },
  },
};
