<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">
          Basisinformationen des Kunden
        </h5>
        <p>
          Bitte fülle <b>ALLE</b> relevanten Informationen aus
        </p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-4">
          <div class="avatar avatar-xxl position-relative">
            <img
              src="@/assets/img/team-2.jpg"
              class="border-radius-md"
              alt="team-2"
            />
          </div>
        </div>
        <div class="mt-4 col-12 col-sm-4 mt-sm-0 text-start">
          <label>Voller Name</label>
          <input
            v-model="this.$parent.fullName"
            class="mb-3 multisteps-form__input form-control"
            type="text"
            placeholder="Max Mustermann"
          />
          <label>Geburtsjahr</label>
          <input
              v-model="this.$parent.birthYear"
            class="mb-3 multisteps-form__input form-control"
            type="number"
            placeholder="1999"
          />
          <div class="my-auto form-check">
            <label>Kinder</label>
            <input
                v-model="this.$parent.hasChildren"
                id="customCheck2"
                class="form-check-input"
                type="checkbox"
                checked
            />
          </div>
        </div>
        <div class="mt-4 col-12 col-sm-4 mt-sm-0 text-start">
          <label>Nettoeinkommen/Wunschrente</label>
          <input
              v-model="this.$parent.nettoeinkommen"
              class="mb-3 multisteps-form__input form-control"
              type="number"
              placeholder="2500"
          />
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="this.$parent.nextStep"
        >
          Weiter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>
