<script>

import BasePagination from "@/components/BasePagination.vue";
import eventTable from "@/mixins/eventTable";
import {DataTable} from "simple-datatables";
import store from "@/store";
import _ from "lodash";

var currentQuery = "";
var currentPerPage = 5;
var currentPage = 1;
var currentSort = "id";
var loading = require("/src/assets/img/loading.gif");

const getArticleListByCategory = _.debounce(async function (category, params) {
  await store.dispatch("cms/getArticlesByCategory", {
    category: category,
    query: params.query,
    sort: params.sort,
    currentPerPage: params.currentPerPage,
    currentPage: params.currentPage,
  });
}, 300);

export default {
  name: "CMSListCard",
  components: {BasePagination},
  mixins: [eventTable],
  props: {
    category: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    newButtonName: {
      type: String,
      required: true,
    },
    newButtonPath: {
      type: String,
      required: true,
    },
    listHeader: {
      type: Array,
      required: true,
    },
    eventToCallOptions: {
      type: Object,
      required: true,
      showPath: {
        type: String,
        required: true,
      },
      categoryId: {
        type: Number,
        required: true,
      },
      redirectPath: {
        type: String,
        required: true,
      },
      deletePath: {
        type: String,
        required: true,
      },
      getPath: {
        type: String,
        required: true,
      },
      textDelete: {
        type: String,
        required: true,
      },
      textDefaultData: {
        type: String,
        required: true,
      },
    }
  },
  data() {
    return {
      meetingsAux: [],
      pagination: {},
      tableItems: null,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    meetingsList() {
      return this.$store.getters["cms/articles"]?.data;
    },
    metaPage() {
      return this.$store.getters["cms/articles"]?.meta;
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.scope == 2) return true;
      }
      return false;
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    meetingsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async created() {

    if (this.$store.getters["auth/loggedIn"]) {
      try {
        await this.$store.dispatch("profile/getProfile");
        this.profileChange = {...this.profile};
      } catch (error) {
        try {
          await this.$store.dispatch("auth/logout");
        } finally {
          await this.$router.push("/login");
        }
      }
    }
  },
  async mounted() {
    const self = this;
    if (this.$refs.cmsList) {
      this.tableItems = new DataTable(this.$refs.cmsList, {
        fixedHeight: false,
        perPage: 5,
      });

      document.querySelector(".dataTable-bottom").remove();
      this.tableItems.label = null;
      this.tableItems.setMessage(
          `<img src="${loading}" width="100" height="100" alt="loading" />`
      );

      await getArticleListByCategory(self.category,{
        query: currentQuery,
        currentPerPage: currentPerPage,
        currentPage: currentPage,
        sort: currentSort,
      });

      this.tableItems.on("datatable.perpage", async function (perpage) {
        this.setMessage(
            `<img src="${loading}" width="100" height="100" alt="loading" />`
        );
        await getArticleListByCategory(self.category,
            {
          query: currentQuery,
          currentPerPage: (currentPerPage = perpage),
          currentPage: (currentPage = 1),
          sort: currentSort,
        });
      });

      this.tableItems.on("datatable.search", async function (query) {
        this.setMessage(
            `<img src="${loading}" width="100" height="100" alt="loading" />`
        );
        await getArticleListByCategory(self.category,{
          query: (currentQuery = query),
          currentPerPage: currentPerPage,
          currentPage: currentPage,
          sort: currentSort,
        });
      });
    }
  },
  beforeUnmount() {
    currentQuery = "";
    currentPerPage = 5;
    currentPage = 1;
    currentSort = "id";
  },
  
  methods: {
    async getDataFromPage(page) {
      await getArticleListByCategory(this.category,{
        query: currentQuery,
        currentPerPage: currentPerPage,
        currentPage: (currentPage = page),
        sort: currentSort,
      });
    },

    shortenText(text, maxLength) {
      if (text && text.length > maxLength) {
        // remove html tags
        text = text.replace(/(<([^>]+)>)/gi, "");
        return text.slice(0, maxLength) + "...";
      }

      if (text === null || text === undefined || text === "" || text === "<p><br></p>") {
        return "Keine Beschreibung vorhanden";
      }

      return text;
    },

    async reactivePagination() {
      this.pagination = this.metaPage;
    },


    getProgressAmount(row) {
      if (row.videoComplete) {
        return 100;
      }
      return row.progress;
    },


    async reactiveTable() {
      this.meetingsAux = [];
      if (this.meetingsList?.length > 0) {
        this.meetingsAux = await Promise.all(this.meetingsList.map(async (row) => {
          return [
            row.articleTitle ? row.articleTitle : "Kein Titel",
            row.createdByName ? row.createdByName : "Kein Ersteller",
            this.shortenText(row.description, 80),
            this.generateActions(row.id),
          ];
        }));

        this.tableItems.data = [];
        this.tableItems.refresh();
        document.querySelector(".dataTable-input").value = currentQuery;
        this.tableItems.insert({data: this.meetingsAux});
        this.removeEvent();
        this.eventToCall({
          table: this.tableItems,
          showPath: this.eventToCallOptions.showPath,
          redirectPath: this.eventToCallOptions.redirectPath,
          deletePath: this.eventToCallOptions.deletePath,
          getPath: this.eventToCallOptions.getPath,
          textDelete: this.eventToCallOptions.textDelete,
          textDefaultData: this.eventToCallOptions.textDefaultData,
          params: {
            category: this.eventToCallOptions.categoryId,
            query: currentQuery,
            currentPerPage: currentPerPage,
            currentPage: currentPage,
            sort: currentSort,
          },
        });
      } else {
        this.tableItems.setMessage("Keine Informationen gefunden");
      }
    },
    generateActions(rowId) {
      let actions = "";
      if (this.requireAdmin) {
        actions = this.actionShowButton(rowId, "Artikel anzeigen") +
        this.actionEditButton(rowId, "Artikel bearbeiten") +
        this.actionDeleteButton(rowId, "Artikel löschen");
      } else {
        actions = this.actionShowButton(rowId, "Artikel anzeigen");
      }
      return actions;
    }
  },
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">{{title}}</h5>
              </div>
              <div v-if="requireAdmin" class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link
                      class="mb-0 btn bg-gradient-success btn-sm"
                      target="_blank"
                      :to="{name: newButtonPath}"
                  >{{newButtonName}}
                  </router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="cms-list" ref="cmsList" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th v-for="header in listHeader" :key="header.title" :title="header.title">{{header.label}}</th>
                </tr>
                </thead>
                <tbody class="text-sm"></tbody>
                <tfoot>
                <tr>
                  <th v-for="header in listHeader" :key="header.title" :title="header.title">{{header.label}}</th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="ms-3">
              <p>
                Zeige {{ pagination?.from }} bis {{ pagination?.to }} von
                {{ pagination?.total }} Einträgen
              </p>
            </div>
            <BasePagination
                class="pagination-success pagination-md me-3"
                :per-page="pagination?.perPage"
                :value="pagination?.currentPage"
                :total="pagination?.total"
                @click="getDataFromPage($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
