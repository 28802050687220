<template>
  <div class="mt-3 col-12 col-md-6 ms-auto text-start">
    <label>Typ der Versorgung</label>
    <select
        :id="'choices-country-' + $props.id"
        v-model="selectedType"
        class="form-control"
        name="choices-country"
    >
      <option value="gRV">Gesetzliche Rente/Altersrente</option>
      <option value="Betriebrente">Betriebrente (Penka)</option>
      <option value="Gehaltsumwandlung">Gehaltsumwandlung / Direktversicherung</option>
      <option value="Riesterrente">Riesterrente</option>
      <option value="Rüruprente">Rüruprente</option>
      <option value="Privatrente">Privatrente</option>
      <option value="Zinseinkünfte">Zinseinkünfte</option>
      <option value="Mieteinkünfte">Mieteinkünfte</option>
    </select>
  </div>
  <div class="mt-3 col-12 col-md-4 ms-auto">
    <label>Höhe der zu erwartenden Rente</label>
    <input
        v-model="selectedAmount"
        class="multisteps-form__input form-control"
        type="number"
        placeholder="0,00"
    />
  </div>
  <div class="mt-3 col-12 col-md-2 ms-auto">
    <label>Entfernen</label>
    <button type="button" class="multisteps-form__input form-control btn btn-outline-danger" @click="removeContract()"><i class="fas fa-trash text-secondary"></i></button>
  </div>
</template>

<script>

import Choices from "choices.js";

export default {
  name: "AV-Vertrag",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "gRV",
    },
    amount: {
      type: Number,
      default: 0.0,
    }
  },
  data() {
    return {
      selectedType: this.type,
      selectedAmount: this.amount,
    };
  },
  watch: {
    selectedType() {
      this.$parent.$parent.avContracts.find(contract => contract.id === this.id).type = this.selectedType;
    },
    selectedAmount() {
      // clear the amount if it is not a number
      if (isNaN(this.selectedAmount)) {
        this.selectedAmount = 0.0;
      }
      // if the amount is negative, make it 0
      if (this.selectedAmount < 0) {
        this.selectedAmount = 0.0;
      }
      this.$parent.$parent.avContracts.find(contract => contract.id === this.id).amount = this.selectedAmount;
    }
  },
  mounted() {
    new Choices(`#choices-country-${this.id}`, {
      searchEnabled: false,
      itemSelectText: "",
    });
  },
  methods: {
    removeContract() {
      this.$parent.$parent.avContracts = this.$parent.$parent.avContracts.filter(contract => contract.id !== this.id);
    },
  }
}
</script>
