<script>
import CMSSinglePage from "@/views/components/cms/CMSSinglePage.vue";

export default {
  name: "RentenClearUpErklaerVideoSinglePage",
  components: {CMSSinglePage},
}
</script>

<template>
 <CMSSinglePage list-element="RentenClearUpErklaerVideoList"></CMSSinglePage>
</template>

<style scoped>

</style>
