<script>
import Dropzone from "dropzone";
import authHeader from "@/services/auth-header";

export default {
  name: "UploadElement",
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    parentName: {
      type: String,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropZone: null,
      uploading: false,
      progress: 0,
      sentBytes: 0,
    };
  },
  watch: {
    endpoint: function (newVal) {
      this.dropZone.options.url = process.env.VUE_APP_API_BASE_URL + '/' + newVal;
    },
  },
  mounted() {
    Dropzone.autoDiscover = false;
    const self = this;
    const drop = document.getElementById("dropzone-documents");
    this.dropZone = new Dropzone(drop, {
      addRemoveLinks: true,
      uploadMultiple: true,
      autoProcessQueue: true,
      maxFilesize: 3000,
      headers: {
        "Authorization": authHeader().Authorization,
      },
    });
    const baseEndpoint = process.env.VUE_APP_API_BASE_URL + '/';
    this.dropZone.options.url = baseEndpoint + this.endpoint
    this.dropZone.options.method = "POST";
    if (self.isMeeting) {
      this.dropZone.on('sending', function (file, xhr, formData) {

        const meetingJson = JSON.stringify({
          meetingVideoId: self.$parent.meeting.id,
        })
        formData.append('meetingFile', meetingJson);
      });
    }

    this.dropZone.on("uploadprogress", function (file, progress, bytesSent) {
      self.progress = Math.round((bytesSent / file.size) * 100);
    });
    this.dropZone.on("success", function (file, response) {
      self.$swal({
        icon: "success",
        title: "Alle notwendigen Dokumente für '" + self.parentName + "' wurden erfolgreich hochgeladen'!",
        //text: "Du wirst in 5 Sekunden weitergeleitet.",
        type: "success-message",
      });
      self.progress = 100;

      response.data.forEach((file) => {
        file.fileTypeObject = self.detectFileType(file.fileType);
      });

      // remove from dropzone
      self.dropZone.removeFile(file);
      self.$parent.files = self.$parent.files.concat(response.data);
    });
  },
  methods: {
    detectFileType(fileType) {
      switch (fileType) {
        case "application/pdf":
          return {
            icon: "fas fa-file-pdf",
            name: "PDF"
          };
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return {
            icon: "fas fa-file-word",
            name: "Word"
          };
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return {
            icon: "fas fa-file-excel",
            name: "Excel"
          };
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return {
            icon: "fas fa-file-powerpoint",
            name: "Powerpoint"
          };
        case "image/png":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/jpeg":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "image/gif":
          return {
            icon: "fas fa-file-image",
            name: "Bild"
          };
        case "video/mp4":
          return {
            icon: "fas fa-file-video",
            name: "Video"
          };
        default:
          return {
            icon: "fas fa-file",
            name: "Datei"
          };
      }
    },
  }
}
</script>

<template>
  <div class="multisteps-form__content">
    <div class="mt-3 row">
      <div class="col-12">
        <label>Hier können neue Dokumente hochgeladen werden</label>
        <form
            id="dropzone-documents"
            action="/file-upload"
            class="form-control dropzone"
        >
          <div class="fallback">
            <input name="file" type="file"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
