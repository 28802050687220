import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
    async getMeetings(params) {
        console.log("params", params)
        const response = await axios.get(API_URL + "meeting", {
            headers: authHeader(),
            params: {
                query: params.query,
                currentPerPage: params.currentPerPage,
                currentPage: params.currentPage,
                sort: params.sort,
            }
        });
        return {
            data: response.data,
        };
    },

    async getMeeting(id) {
        const response = await axios.get(API_URL + "meeting/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async deleteMeetingFileByID(id) {
        const response = await axios.delete(API_URL + "meeting/files/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getMeetingFiles(id) {
        const response = await axios.get(API_URL + "meeting/files/video/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getRandomMeetingByTags(search) {
        const tags = search.tags
        const response = await axios.post(API_URL + "meeting/tags/" + search.limit, tags, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getMeetingProgressByIds(ids) {
        const response = await axios.post(API_URL + "meeting/progress/ids", ids, {
            headers: authHeader(),
        });
        return response.data;
    },

    async fetchVideoStream(id) {
        const response = await axios.get(API_URL + "meeting/stream/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getMeetingThumbnail(id) {
        const response = await axios.get(API_URL + "meeting/thumbnail/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async updateMeetingProgress(progress) {
        const response = await axios.put(API_URL + "meeting/progress", progress, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getMeetingProgress(id) {
        const response = await axios.get(API_URL + "meeting/progress/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getAllMeetingProgress() {
        const response = await axios.get(API_URL + "meeting/progress/all", {
            headers: authHeader(),
        });
        return response.data;
    },

    async deleteMeeting(id) {
        const response = await axios.delete(API_URL + "meeting/" + id.toString(), {
            headers: authHeader(),
        });
        return response.data;
    },

    async createMeeting(meeting, file) {
        return await axios.post(
            API_URL + "meeting",
            {
                file: file,
                meeting: meeting
            },
            {
                headers: authHeader(),
            }
        );
    },

    async editMeeting(meeting) {
        return await axios.put(
            API_URL + "meeting",
            meeting,
            {
                headers: authHeader(),
            }
        );
    },
};
