<template>
  <div class="col-12">
    <h5 class="ms-3">Weitere Meeting Videos</h5>
    <div class="table table-responsive">
      <table class="table mb-0 align-items-center">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Meeting
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Referent
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Beschreibung
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Fortschritt
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Id
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="meeting in meetings" :key="meeting.id" @click="directToMeeting(meeting.id)" style="cursor: pointer">
            <td>
              <div class="px-2 py-1 d-flex">
                <div>
                  <img
                    :src="meeting.thumbnailUrl"
                    class="avatar avatar-md me-3"
                    alt="table image"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{meeting.meetingName}}</h6>
                </div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">{{meeting.uploadedByFullname}}</span>
            </td>
            <td>
              <!--span v-for="tag in meeting.tags" :key="tag" class="badge badge-success"
                    style="margin-right: 5px">{{ tag }}</span-->
              <span class="text-sm text-secondary">{{shortenText(meeting.description, 80)}}</span>
            </td>
            <td class="text-sm align-middle">
              <div class="mx-auto progress">
                <div
                  class="progress-bar bg-gradient-success"
                  role="progressbar"
                  :style="'width: ' + getProgressAmount(meeting) + '%;'"
                  :aria-valuenow="getProgressAmount(meeting)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">{{meeting.id}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherMeetingVideos",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    currentMeetingId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      meetings: [],
      progressList: [],
    };
  },
  async mounted() {
    // convert tags to string array
    this.string_tags =this.tags.map((tag) => tag);
    const temp_meetings = await this.$store.dispatch("meetings/getRandomMeetingByTags", {
      tags: this.string_tags,
      limit: 4,
    });

    const idMap = temp_meetings.data.map((meeting) => meeting.id);

    this.progressList = await this.$store.dispatch("meetings/getMeetingProgressByIds", idMap);

    const progress = this.progressList.data;

    // remove current meeting from list
    this.meetings = temp_meetings.data.filter((meeting) => meeting.id !== this.currentMeetingId);

    const notFound = [];
    for (let i = 0; i < this.meetings.length; i++) {
      let found = false;
      for (let j = 0; j < progress.length; j++) {
        if (this.meetings[i].id === progress[j].meetingVideoId) {
          found = true;
          this.meetings[i].progress = progress[j].progress;
          this.meetings[i].videoComplete = progress[j].completed;
          break;
        }
      }
      if (!found) {
        notFound.push(this.meetings[i].id);
      }
    }
  },
  // check if parent component has changed the tags
  watch: {
    currentMeetingId: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // convert tags to string array
        this.string_tags =this.tags.map((tag) => tag);
        const temp_meetings = await this.$store.dispatch("meetings/getRandomMeetingByTags", {
          tags: this.string_tags,
          limit: 4,
        });

        const idMap = temp_meetings.data.map((meeting) => meeting.id);

        this.progressList = await this.$store.dispatch("meetings/getMeetingProgressByIds", idMap);

        const progress = this.progressList.data;

        // remove current meeting from list
        this.meetings = temp_meetings.data.filter((meeting) => meeting.id !== this.currentMeetingId);

        const notFound = [];
        for (let i = 0; i < this.meetings.length; i++) {
          let found = false;
          for (let j = 0; j < progress.length; j++) {
            if (this.meetings[i].id === progress[j].meetingVideoId) {
              found = true;
              this.meetings[i].progress = progress[j].progress;
              this.meetings[i].videoComplete = progress[j].completed;
              break;
            }
          }
          if (!found) {
            notFound.push(this.meetings[i].id);
          }
        }
      }
    },
  },
  methods: {
    shortenText(text, maxLength) {
      if (text && text.length > maxLength) {
        text = text.replace(/(<([^>]+)>)/gi, "");
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    getProgressAmount(meeting) {
      if (meeting.videoComplete) {
        return 100;
      }
      return meeting.progress;
    },
    directToMeeting(id) {
     this.$parent.directToMeeting(id);
    },
  }
};
</script>
