import cmsService from "@/services/cms.service";

const initialState = {articles: null, categories: null, files: null};

export const cms = {
    namespaced: true,
    state: initialState,
    actions: {
        /**
         * Article functions
         */
        async getArticles({commit}, params) {
            const response = await cmsService.getAllArticles(params);
            commit("SET_ARTICLES", response.data);
        },

        async getArticlesByCategory({commit}, params) {
            console.log("params", params)
            const response = await cmsService.getArticlesByCategory(params);
            commit("SET_ARTICLES", response.data);
        },

        async getArticle(_, params) {
            console.log("params", params);
            return await cmsService.getArticleById(params.id, params.withStream);
        },

        async updateArticle(_, params) {
            return await cmsService.updateArticle(params.article, params.hasVideo, params.file);
        },

        async deleteArticle(_, id) {
            return await cmsService.deleteArticle(id);
        },
        /**
         * Category functions
         */
        async getCategories({commit}) {
            const response = await cmsService.getCategories();
            commit("SET_CATEGORIES", response.data);
        },
        /**
         * File functions
         */
        async getFiles({commit}) {
            const response = await cmsService.getFiles();
            commit("SET_FILES", response.data);
        },

        async deleteFileById(_, id) {
            return await cmsService.deleteFile(id);
        }
    },

    mutations: {
        SET_ARTICLES(state, articles) {
            state.articles = articles;
        },
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        SET_FILES(state, files) {
            state.files = files;
        },
    },

    getters: {
        articles(state) {
            return state.articles;
        },
        categories(state) {
            return state.categories;
        },
        files(state) {
            return state.files;
        },
    }
}
