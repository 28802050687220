<script>

import NewArticle from "@/views/components/cms/new/NewArticle.vue";

export default {
  name: "AktuellesNew",
  components: {
    NewArticle,
  },
}
</script>

<template>
  <NewArticle :category-id="9"></NewArticle>
</template>

<style scoped>

</style>
