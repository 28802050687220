<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">Erfassung der Altersvorsorge-Verträge</h5>
        <p>
          Bitte erfasse alle Altersvorsorge-Verträge des Kunden + die gesetzliche Rentenversicherung
        </p>
      </div>
    </div>
    <div class="multisteps-form__content row">
      <!-- set another class if help is false -->
      <div :class="{
      'mt-3 col-12 col-md-12 ms-auto': this.$parent.gkvVersichert,
      'mt-3 col-12 col-md-6 ms-auto text-start': this.$parent.pkvVersichert
    }"
      >
        <label>Welcher Inflationssatz soll für die Berechnung herangezogen werden?</label>
        <input
            v-model="this.$parent.inflation"
            class="multisteps-form__input form-control"
            type="number"
            placeholder="0,00 %"
        />
      </div>
      <div v-if="this.$parent.pkvVersichert" class="mt-3 col-12 col-md-6 ms-auto">
        <label>Wie Hoch ist die zu erwartende Prämie in der PKV?</label>
        <input
            v-model="this.$parent.pkvPraemie"
            class="multisteps-form__input form-control"
            type="number"
            placeholder="0.00"
        />
      </div>
      <div class="row text-start">
        <AVVertrag v-for="contract in this.$parent.avContracts" :id="contract.id" :key="contract.id"
                   :type="contract.type" :amount="contract.amount"></AVVertrag>
        <div class="mt-3 col-12 col-md-4 ms-auto">
          <button type="button" class="btn btn-success btn w-100" @click="addNewContract()">neuer Vertrag</button>
        </div>
      </div>
      <div class="mt-3 col-12 col-md-4 ms-auto">
        <p class="right">Aktuelle zu ertwartende Rente: {{ calculateTotalAmount() }}</p>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button
              class="mb-0 btn bg-gradient-light js-btn-prev"
              type="button"
              title="Prev"
              @click="this.$parent.prevStep"
          >
            Zurück
          </button>

          <div class="mt-3 col-12 col-md-6 ms-auto">

            <button
                class="mb-0 btn bg-gradient-success"
                type="button"
                title="Send"
                @click="printAndSave()"
            >
              Renten ClearUp erstellen und Speichern
            </button>
          </div>
          <div class="mt-3 col-12 col-md-3 ms-auto">
            <button
                class="mb-0 btn bg-gradient-dark"
                type="button"
                title="Send"
                @click="printAllContracts()"
            >
              Renten ClearUp erstellen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AVVertrag from "@/views/applications/vorsorgeaufstellung/components/AV-Vertrag.vue";
import showSwal from "@/mixins/showSwal";

export default {
  name: "Address",
  components: {AVVertrag},
  methods: {
    addNewContract() {
      const newContract = {
        id: this.$parent.avContracts.length + 1,
        type: "gRV",
        amount: 0.0,
      }
      this.$parent.avContracts.push(newContract);
    },
    async printAllContracts() {
      showSwal.methods.showSwal({
        type: "success",
        message: "Die Aufstellung wird nun generiert. Bitte warte einen Moment.",
      });
      const summary = this.$parent.generateFullSummary();
      // make axios post request to backend
      const response = await this.$store.dispatch("vorsorgeaufstellung/createVorsorgeaufstellung", summary);

      // Convert the byte array into a Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Set the MIME type for an Excel file
      });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      // date in format DDMMYYYY
      const date = new Date().toLocaleDateString("de-DE").replace(/\./g, "");
      link.download = "Renten-Clear-Up-"+this.$parent.fullName.replace(" ", "_")+"-"+date+".xlsx"; // Set the desired filename

      // Trigger the download
      link.click();

      // check if the download was successful
      if (response.status !== 200) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Die Aufstellung konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.",
        });
        return;
      }

      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);
    },
    async printAndSave() {
      showSwal.methods.showSwal({
        type: "error",
        message: "Die Aufstellung wird nun generiert, und gespeichert. Bitte warten Sie einen Moment. Diese Funktion ist noch nicht implementiert.",
      });
    },
    calculateTotalAmount() {
      let totalAmount = 0.00;
      this.$parent.avContracts.forEach(contract => {
        if (contract.amount === null) {
          contract.amount = 0.00;
        }
        if (typeof contract.amount === "string") {
          contract.amount = 0.00;
        }
        totalAmount += contract.amount;
      });
      // add two decimal places to the total amount and return it
      // check if the total amount is a number
      if (isNaN(totalAmount)) {
        return "0.00 €";
      }

      totalAmount = totalAmount.toFixed(2);
      return totalAmount + " €";
    },
  },
};
</script>
