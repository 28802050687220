<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Artikel Informationen</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-12" style="margin-bottom: 15px">
          <label>Titel</label>
          <input
            v-model="this.$parent.article.articleTitle"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="Bsp.: Renten-Clear-Up"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label class="mt-4">Beschreibung</label>
          <p class="text-xs form-text text-muted ms-1 d-inline">
            (optional)
          </p>
          <div id="edit-description-edit" class="h-50">
            <p></p>
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="this.$parent.nextStep"
          >WEITER</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "ArticleInfo",
  components: {
    SoftButton,
  },
  mounted() {
    if (document.getElementById("edit-description")) {
      // eslint-disable-next-line no-unused-vars
     new Quill("#edit-description", {
        theme: "snow", // Specify theme in configuration
      });
    }
    if (document.getElementById("choices-category")) {
      const element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-sizes")) {
      let element = document.getElementById("choices-sizes");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("edit-description-edit")) {
      this.quill = new Quill("#edit-description-edit", {
        theme: "snow", // Specify theme in configuration
      });
      this.quill.root.innerHTML = this.$parent.article.description;
    }

    // Watch for changes in the Quill editor's content
    this.quill.on("text-change", () => {
      // Update the meeting's description property with the editor's content
      this.$parent.article.description = this.quill.root.innerHTML;
    });
  },
};
</script>
