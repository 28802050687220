<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Stichwörter</h5>
    <div class="mt-3 multisteps-form__content">
      <div class="row">
        <div class="col-12">
          <input
              id="choices-tags"
              class="form-control"
              type="text"
              :value="getStringTagsAsString()"
              placeholder="Bitte gib Stichwörter ein"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
              @change="handleTagChange($event)"
          />
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Prev"
          @click="this.$parent.prevStep"
          >Zurück</soft-button
        >
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="this.$parent.nextStep"
          >Weiter</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "Stichwoerter",
  components: {
    SoftButton,
  },
  methods: {
    handleTagChange(event) {
      this.$parent.meeting.tags = event.target.value.split(",");
    },
    getStringTagsAsString() {
      return this.$parent.meeting.tags.join(",");
    },
  },
  mounted() {
    if (document.getElementById("choices-currency")) {
      let element = document.getElementById("choices-currency");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-tags")) {
      var tags = document.getElementById("choices-tags");
      new Choices(tags, {
        removeItemButton: true,
      });
    }

    if (document.getElementById("choices-tags")) {
      const skills = document.getElementById("choices-tags");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
};
</script>
