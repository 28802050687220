import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {

  async createVorsorgeaufstellung(futureInvest) {
    const response = await axios.post(
      API_URL + "vorsorgeaufstellung",
      futureInvest,
      {
        responseType: "arraybuffer",
        headers: authHeader(),
      }
    );
    return response;
  },
};
