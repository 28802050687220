<script>
import CMSListCard from "@/views/components/cms/CMSListCard.vue";

export default {
  name: "AnwerbungList",
  components: {CMSListCard},
  data() {
    return {
      tableItems: null,
      tableHeader: [
        {
          title: "article",
          label: "Titel",
        },
        {
          title: "creator",
          label: "Erstellt von",
        },
        {
          title: "description",
          label: "Beschreibung",
        },
        {
          title: "Action",
          label: "Aktionen",
        }
      ]
    };
  },
}
</script>

<template>
  <CMSListCard
      title="Anwerbungs Informationen & Dokumente"
      new-button-name="Neue(s) Dokument/Information"
      :list-header="tableHeader"
      :category=5
      :event-to-call-options="{
        table: tableItems,
        showPath: 'Anwerbung',
        redirectPath: 'AnwerbungEdit',
        categoryId: 5,
        deletePath: 'cms/deleteArticle',
        getPath: 'cms/getArticlesByCategory',
        textDelete: 'Artikel wurde erfolgreich gelöscht!',
        textDefaultData: 'anwerbung'
      }"
   new-button-path="AnwerbungNew"></CMSListCard>
</template>

<style scoped>

</style>
