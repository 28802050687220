import meetingsService from "@/services/meetings.service";

const initialState = { meetings: null, meetingsProgress: null };

export const meetings = {
  namespaced: true,
  state: initialState,
  actions: {
    async getMeetings({ commit }, params) {
      const response = await meetingsService.getMeetings(params);
      commit("SET_MEETINGS", response.data);
    },

    async getMeeting(_, id) {
      return await meetingsService.getMeeting(id);
    },

    async getMeetingFiles(_, id) {
        return await meetingsService.getMeetingFiles(id);
    },

    async deleteMeetingFileByID(_, id) {
        return await meetingsService.deleteMeetingFileByID(id);
    },

    async getRandomMeetingByTags(_, searchLimiter) {
        return await meetingsService.getRandomMeetingByTags(searchLimiter);
    },

    async getMeetingProgressByIds(_, ids) {
        return await meetingsService.getMeetingProgressByIds(ids);
    },

    async deleteMeeting(_, id) {
      await meetingsService.deleteMeeting(id);
    },

    async fetchVideoStream(_, id) {
        return await meetingsService.fetchVideoStream(id);
    },

    async getMeetingThumbnail(_, id) {
        return await meetingsService.getMeetingThumbnail(id);
    },

    async updateMeetingProgress(_, progress) {
        return await meetingsService.updateMeetingProgress(progress);
    },

    async getMeetingProgress(_, id) {
        return await meetingsService.getMeetingProgress(id);
    },

    async getAllMeetingProgress({ commit }) {
      const response = await meetingsService.getAllMeetingProgress();
      commit("SET_MEETINGS_PROGRESS", response);
    },



    async addMeeting(_, meeting, file) {
      return await meetingsService.createMeeting(meeting, file);
    },

    async editMeeting(_, meeting) {
      return await meetingsService.editMeeting(meeting);
    },
  },

  mutations: {
    SET_MEETINGS(state, meetings) {
      state.meetings = meetings;
    },
    SET_MEETINGS_PROGRESS(state, meetingsProgress) {
      state.meetingsProgress = meetingsProgress;
    },
  },

  getters: {
    meetings(state) {
      return state.meetings;
    },
    meetingsProgress(state) {
      return state.meetingsProgress;
    },
  },
};
