<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Dokumente</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12">
          <label>Bitte hier die Dokumente hochladen, falls vorhanden. Sonst zum Artikel gehen</label>
          <form
              id="dropzone-documents"
              action="/file-upload"
              class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file"/>
            </div>
          </form>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
        >Zurück
        </soft-button
        >
        <soft-button
            type="button"
            color="success"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Zum Artikel"
            @click="pushToMeeting"
        >Zum Artikel
        </soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Dropzone from "dropzone";
import authHeader from "@/services/auth-header";
import cmsService from "@/services/cms.service";

export default {
  name: "CmsDocuments",
  components: {
    SoftButton,
  },
  props: {
    articleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dropZone: null,
      uploading: false,
      progress: 0,
      sentBytes: 0,
      categorySlug: "",
    };
  },
  watch: {
    articleId: async function (val) {
      this.dropZone.options.url = process.env.VUE_APP_API_BASE_URL +"/" + "cms/files/" + val;
      const response = await cmsService.getCategoryById(this.$parent.article.cmsCategory);
      this.categorySlug = response.data.slugName;
    },
  },
  mounted() {
    Dropzone.autoDiscover = false;
    const self = this;
    const drop = document.getElementById("dropzone-documents");
    this.dropZone = new Dropzone(drop, {
      addRemoveLinks: true,
      uploadMultiple: true,
      autoProcessQueue: true,
      maxFilesize: 3000,
      headers: {
        "Authorization": authHeader().Authorization,
      },
    });
    const baseEndpoint = process.env.VUE_APP_API_BASE_URL + '/';
    this.dropZone.options.url = baseEndpoint + "cms/files/"  + this.articleId;
    this.dropZone.options.method = "POST";

    this.dropZone.on("uploadprogress", function (file, progress, bytesSent) {
      self.progress = Math.round((bytesSent / file.size) * 100);
    });
    this.dropZone.on("success", function (file, response) {
      self.$swal({
        icon: "success",
        title: "Alle notwendigen Dokumente für '"+self.$parent.article.articleTitle+"' wurden erfolgreich hochgeladen'!",
        //text: "Du wirst in 5 Sekunden weitergeleitet.",
        type: "success-message",
      });
      console.log("success", response);
      self.progress = 100;

    });
  },
  methods: {
    pushToMeeting() {
      console.log("push to meeting", this.$parent.article.id);
      this.$router.push({
        name: this.categorySlug,
        params: {
          id: this.articleId,
        },
      });
    },
  },
};
</script>
