import axios from "axios";
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
    async getAllArticles(params) {
        const response = await axios.get(API_URL + "cms/articles", {
            headers: authHeader(),
            params: {
                query: params.query,
                currentPerPage: params.currentPerPage,
                currentPage: params.currentPage,
                sort: params.sort,
            }
        });
        return {
            data: response.data,
        };
    },

    async getArticlesByCategory(params) {
        const response = await axios.get(API_URL + "cms/articles/category/" + params.category, {
            headers: authHeader(),
            params: {
                query: params.query,
                currentPerPage: params.currentPerPage,
                currentPage: params.currentPage,
                sort: params.sort,
            }
        });
        return {
            data: response.data,
        };
    },

    async getArticlesByCategorySlug(category, params) {
        const response = await axios.get(API_URL + "cms/articles/category/slug/" + category, {
            headers: authHeader(),
            params: {
                query: params.query,
                currentPerPage: params.currentPerPage,
                currentPage: params.currentPage,
                sort: params.sort,
            }
        });
        return {
            data: response.data,
        };
    },

    async getArticleById(id, withStream) {
        const response = await axios.get(API_URL + "cms/articles/" + id, {
            headers: authHeader(),
            params: {
                withStream: withStream,
            }
        });
        return response.data;
    },

    async createArticle(article, hasVideo, file) {
        let formData = new FormData();
        if (hasVideo) {
            formData.append('file', file)
            formData.append("hasVideo", hasVideo)
        }
        const json = JSON.stringify(article);
        formData.append('article', json);
        const response = await axios.post(API_URL + "cms/articles", formData, {
            headers: authHeader(),
        });
        return response.data;
    },

    async updateArticle(article, hasVideo, file) {
        // only send hasVideo and file if they are set
        let formData = new FormData();
        const rewrittenArticle = {
            id: article.id,
            articleTitle: article.articleTitle,
            active: article.active,
            description: article.description,
            cmsCategory: article.cmsCategory,
        }
        if (hasVideo) {
            rewrittenArticle.hasVideo = hasVideo;
            formData.append('file', file)
            formData.append("hasVideo", hasVideo)
        }
        const json = JSON.stringify(rewrittenArticle);
        formData.append('article', json);
        const response = await axios.put(API_URL + "cms/articles/" + article.id, formData, {
            headers: authHeader(),
        });
        return response.data;
    },

    async deleteArticle(id) {
        const response = await axios.delete(API_URL + "cms/articles/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    /**
     * Now all functions for categories
     */
    async getCategories() {
        const response = await axios.get(API_URL + "cms/categories", {
            headers: authHeader(),
        });
        return response.data;
    },

    async getCategoryById(id) {
        const response = await axios.get(API_URL + "cms/categories/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async updateCategory(category) {
        const response = await axios.put(API_URL + "cms/categories", category, {
            headers: authHeader(),
        });
        return response.data;
    },

    async deleteCategory(id) {
        const response = await axios.delete(API_URL + "cms/categories/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async createCategory(category) {
        const response = await axios.post(API_URL + "cms/categories", category, {
            headers: authHeader(),
        });
        return response.data;
    },

    /**
     * Now all functions for files
     */
    async getFiles() {
        const response = await axios.get(API_URL + "cms/files", {
            headers: authHeader(),
        });
        return response.data;
    },

    async getFileById(id) {
        const response = await axios.get(API_URL + "cms/files/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async getFilesByArticleId(id) {
        const response = await axios.get(API_URL + "cms/files/article/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },

    async uploadArticleFile(articleId, file) {
        const response = await axios.post(API_URL + "cms/files/" + articleId, {
            file: file
        }, {
            headers: authHeader(),
        });
        return response.data;
    },

    async deleteFile(id) {
        const response = await axios.delete(API_URL + "cms/files/" + id, {
            headers: authHeader(),
        });
        return response.data;
    },
}
